<template>
  <v-container fluid class="info-art-related px-0">
    <v-form @submit.prevent="onSubmit" action="." ref="form">
      <v-row no-gutters>
        <v-col cols="12" class="pb-3">
          <h3>{{ $t('information.search_article_title') }}</h3>
        </v-col>
        <v-col
          :class="{
            'pr-0': $vuetify.breakpoint.mdAndUp,
            'pt-0': $vuetify.breakpoint.smAndDown,
          }"
          md="8"
          id="SearchComponent"
        >
          <v-combobox
            height="44"
            :class="[isMobile ? 'search-box-mobile' : 'search-box', 'pb-0']"
            item-value="city"
            item-text="text"
            :value="selectedItemValue.text"
            dense
            flat
            solo
            outlined
            clearable
            :items="items"
            no-filter
            :loading="citySearch.isLoading"
            :search-input.sync="search"
            @change="onChange"
            @click:clear="onClear"
            color="secondary"
            append-icon=""
            return-object
            :placeholder="$t('information.city_search')"
            label=""
            aria-labelledby="SearchComponent"
            aria-selected="false"
            tabindex="0"
          >
            <template v-slot:prepend-inner>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.7143 13.8806L11.1785 17.4164C11.0239 17.5712 10.8402 17.694 10.6381 17.7778C10.436 17.8616 10.2194 17.9047 10.0006 17.9047C9.78179 17.9047 9.56515 17.8616 9.36303 17.7778C9.16092 17.694 8.9773 17.5712 8.82267 17.4164L5.286 13.8806C4.35368 12.9482 3.71878 11.7603 3.46157 10.4671C3.20437 9.17394 3.33641 7.83352 3.841 6.61536C4.3456 5.39721 5.20008 4.35604 6.2964 3.62351C7.39272 2.89098 8.68164 2.5 10.0002 2.5C11.3187 2.5 12.6076 2.89098 13.7039 3.62351C14.8003 4.35604 15.6547 5.39721 16.1593 6.61536C16.6639 7.83352 16.796 9.17394 16.5388 10.4671C16.2816 11.7603 15.6466 12.9482 14.7143 13.8806V13.8806Z"
                  stroke="var(--v-primary-base)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5 9.16602C12.5 9.82906 12.2366 10.4649 11.7678 10.9338C11.2989 11.4026 10.663 11.666 10 11.666C9.33696 11.666 8.70107 11.4026 8.23223 10.9338C7.76339 10.4649 7.5 9.82906 7.5 9.16602C7.5 8.50297 7.76339 7.86709 8.23223 7.39825C8.70107 6.92941 9.33696 6.66602 10 6.66602C10.663 6.66602 11.2989 6.92941 11.7678 7.39825C12.2366 7.86709 12.5 8.50297 12.5 9.16602V9.16602Z"
                  stroke="var(--v-primary-base)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
            <template v-slot:prepend-item>
              <v-list-item
                class="item-text"
                ripple
                @click="getUserLocationAndRedirect"
              >
                <v-list-item-action>
                  <v-icon color="secondary">mdi-near-me</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="item-text">
                    {{ $t('information.current_location') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template slot="item" slot-scope="{ item }">
              <v-list-item-action class="mr-2">
                <v-icon color="secondary">mdi-map-marker-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="item-text"
                  >{{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="auto" md="4" class="text-capitalize pl-2">
          <v-btn
            color="primary"
            type="submit"
            dense
            height="44"
            :width="$vuetify.breakpoint.smAndDown ? 80 : 111"
            depressed
            class="search-button size16-weight700"
          >
            {{ $t('search') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import InformationSearchMixins from '@/modules/information/mixins/InformationSearchMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'InfoLandingSearch',
  mixins: [InformationSearchMixins, GoogleTagManagerMixins],
  props: {
    isMobile: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      selectedItemValue: {
        text: '',
        longLat: [],
        city: '',
      },
      search: null,
      timer: null,
    };
  },

  methods: {
    getUserLocationAndRedirect() {
      this.$getLocation().then(
        (coordinates) => {
          this.performReverseGeocode({
            lat: coordinates.lat,
            lng: coordinates.lng,
          });
        },
        (rejected) => {
          //Just to catch the rejection so chrome console doesn't complain
        }
      );
    },

    searchCityDebounced(cityName) {
      // cancel pending call
      clearTimeout(this.timer);

      // delay new call 400ms
      this.timer = setTimeout(() => {
        this.performCitySearch(cityName);
      }, 400);
    },

    onSubmit() {
      this.pushInformationSearchButtonClickAnalytics(
        this.selectedItemValue.city
      );

      if (this.selectedItemValue.city) {
        this.updateSearchQuery(this.selectedItemValue);
      } else {
        this.updateSearchQuery();
      }
    },

    onClear() {
      this.selectedItemValue = {
        text: '',
        longLat: [],
        city: '',
      };
      this.search = null;
    },

    onChange(args) {
      if (args) {
        this.selectedItemValue = args;
      } else {
        this.selectedItemValue = {
          text: null,
        };
      }
    },
  },

  watch: {
    ['$route.query']: {
      immediate: true,
      deep: true,
      async handler(val) {
        if (val.city) {
          this.selectedItemValue = await this.checkQuery();
        }
      },
    },

    search: {
      immediate: true,
      handler(val) {
        if (!val || val === this.selectedItemValue.text) {
          return;
        }
        this.searchCityDebounced(val);
      },
    },

    reverseGeocodeResult(val, oldVal) {
      if (!!val && val !== oldVal) {
        for (let place of val.features) {
          if (place.place_type.length > 0 && place.place_type[0] == 'place') {
            this.updateSearchQuery(this.$route.params, {
              city: place.text,
              longLat: place.center,
            });
          }
        }
      }
    },

    selectedContextFlight(val, oldVal) {
      if (val) {
        this.selectedItemValue = this.searchFlightCity(val, oldVal);
      }
    },
  },

  computed: {
    setColsBreackpoints() {
      let cols = '';
      if (this.$vuetify.breakpoint.lgOnly) {
        cols = '5';
      } else if (this.$vuetify.breakpoint.xlOnly) {
        cols = 'auto';
      } else if (this.$vuetify.breakpoint.xsOnly) {
        cols = '11';
      }
      return cols;
    },

    items() {
      if (!this.hasCitySearch || !this.search) {
        return [];
      } else {
        return this.citySearch.result.features.map((result) => ({
          city: result.text,
          longLat: result.center,
          text: result.place_name,
        }));
      }
    },

    reverseGeocodeResult() {
      return this.reverseGeocode.result;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-art-related {
  margin: 21px 0 0;
  padding-top: 38px;
  border-top: 1px solid #cfd5e0;

  h3 {
    @include font-size(20, 30, 700);
    color: #0a0e14;
  }
}

.v-input__slot .v-text-field__slot > input {
  font-size: 14px;
}
.search-box {
  width: 100%;
  border-radius: 8px;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    width: 400px;
  }

  ::v-deep fieldset {
    border: 1px solid #cfd5e0;
  }
}
::v-deep .search-box-mobile {
  width: 100%;

  &.v-text-field .v-input__append-inner {
    padding: 0;
  }
}
.search-button {
  border-radius: 8px;
}
</style>
