<template>
  <v-container class="info-no-articles">
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center">
        <v-img
          :alt="$t('information.no_articles')"
          src="@/assets/no_items.png"
          height="86"
          contain
        />
        <p class="pt-8">
          {{ $t('information.no_articles') }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'InfoLandingNoArticles',
};
</script>

<style scoped lang="scss">
.info-no-articles {
  padding-top: 85px;
}
</style>
