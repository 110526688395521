<template>
  <v-container fluid class="info-landing-header fill-height">
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center">
        <h1 class="page-title">{{ $t('information.title') }}</h1>
        <p class="page-subtitle">
          {{ $t('information.subtitle') }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'InfoLandingHeader',
};
</script>

<style scoped lang="scss">
.info-landing-header {
  min-height: 120px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    min-height: 250px;
  }
}

.page {
  &-title {
    @include font-size(32, 150, 300);
    color: var(--v-grey9-base);
    margin-bottom: 24px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      @include font-size(36, 150, 300);
      margin-bottom: 18px;
    }
  }

  &-subtitle {
    @include font-size(16, 140, 600);
    color: var(--v-grey8-base);
    margin-bottom: 0;

    @media (min-width: map-get($grid-breakpoints, md)) {
      @include font-size(18, 150, 300);
    }
  }
}
</style>
