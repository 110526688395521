<script>
export default {
  name: 'ILArticleHelperMixins',
  props: {
    article: {
      type: Object,
      default: () => ({
        id: 7,
        title:
          'WestJet makes difficult decision to suspend service to four cities in Atlantic Canada',
        description:
          'CALGARY, AB, Oct. 14, 2020 /CNW/ - Today, WestJet announced it will be indefinitely suspending operations to Moncton, Fredericton, Sydney...',
        date: 'October 7, 2020',
        tags: ['covid-19', 'health'],
        thumbnail: 'https://cdn.vuetifyjs.com/images/parallax/material.jpg',
        resource: 'information/4',
      }),
    },
  },
  computed: {
    articleId() {
      return this.article.id;
    },
    date() {
      return this.article.date;
    },
    tags() {
      return this.article.tags.map((tag) => `#${tag}`).join(', ');
    },
    thumbnail() {
      if (this.article.thumbnail.startsWith('http')) {
        return this.article.thumbnail;
      } else {
        return 'https://' + this.article.thumbnail;
      }
    },
    isExternalResource() {
      return this.resource.startsWith('http');
    },
    resource() {
      if (this.article.resource.startsWith(location.origin)) {
        return this.article.resource.replace(location.origin, '');
      } else {
        return this.article.resource;
      }
    },
  },
  methods: {
    convertArticleTitle(text) {
      return text
        .replace(/p>/g, 'h3>')
        .replace('<h3>', '<h3 class="article-title">');
    },
    convertArticleDescription(text) {
      return text
        .replace(/p>/g, 'p>')
        .replace('<p>', '<p class="article-body">');
    },
  },
};
</script>

<style scoped></style>
