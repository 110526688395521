<template>
  <v-container fluid class="pa-0">
    <vue-headful title="Information Center" />
    <v-row no-gutters>
      <v-col cols="12">
        <InfoLandingHeader />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <template v-if="isLoadingContent">
          <v-skeleton-loader
            class="mx-auto"
            type="image, article, list-item-avatar-three-line@3"
          />
        </template>

        <template v-else>
          <InfoLandingContent />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InfoLandingHeader from '@/modules/information/features/infoLanding/InfoLandingHeader';
import InfoLandingContent from '@/modules/information/features/infoLanding/InfoLandingContent';
import vueHeadful from 'vue-headful';
import InformationSearchMixins from '@/modules/information/mixins/InformationSearchMixins';

export default {
  name: 'InformationLandingPage',
  mixins: [InformationSearchMixins],
  components: {
    InfoLandingContent,
    InfoLandingHeader,
    vueHeadful,
  },

  mounted() {
    if (this.selectedContextFlight && !this.$route.query?.city) {
      this.autoSearch();
    } else {
      this.loadArticlesByQuery();
    }
  },

  methods: {
    goToErrorPage() {
      const notFoundError =
        this.articlesCategoriesError &&
        this.articlesCategoriesError.status >= 400;
      const internalServerError =
        this.articlesCategoriesError &&
        this.articlesCategoriesError.status >= 500;

      if (internalServerError) this.$router.replace('/500');
      else if (notFoundError) this.$router.replace('/404');
    },
  },

  watch: {
    hasInformationStagesError(val) {
      if (!!val) {
        this.goToErrorPage();
      }
    },
  },
};
</script>

<style scoped></style>
