<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h3>{{ $t('information.featured') }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <component
          :is="isExternalResource ? 'a' : 'router-link'"
          :href="resource"
          :target="isExternalResource ? '_blank' : ''"
          :to="{ name: 'information-details', params: { id: articleId } }"
          class="info-landing-article-link"
        >
          <v-container class="pa-0" fluid>
            <v-row class="featured-img">
              <v-col cols="11" sm="8">
                <v-card elevation="0">
                  <v-img
                    :alt="article.title"
                    :src="thumbnail"
                    :aspect-ratio="16 / 9"
                    height="340"
                  />
                </v-card>
              </v-col>
            </v-row>
            <v-row class="featured-text" justify="end">
              <v-col cols="11" sm="6" offset="2" class="mt-5">
                <v-card elevation="0" class="article-card">
                  <v-container>
                    <v-row no-gutters>
                      <v-col
                        offset="0"
                        offset-sm="1"
                        offset-md="3"
                        class="pl-6"
                      >
                        <h3 class="h3">
                          {{ article.title }}
                        </h3>
                        <p class="body-2">
                          {{ article.description }}
                        </p>
                        <p class="caption d-sm-flex d-none">
                          {{ tags }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ILArticleHelperMixins from '@/modules/information/mixins/ILArticleHelperMixins';

export default {
  name: 'InfoLandingFeaturedArticle',
  mixins: [ILArticleHelperMixins],
};
</script>

<style scoped lang="scss">
.info-landing-article-link {
  text-decoration: none;
  color: black;
}

.featured-img {
  z-index: 1;
  position: relative;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    position: absolute;
    width: 80%;
  }
}
.featured-text {
  position: relative;
  top: -150px;
  padding-top: 100px;
  margin-bottom: -150px;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    height: 340px;
    top: 0;
    padding-top: 0;
    margin-bottom: 0;
  }

  .article-card {
    background: #f9f9fb;
    box-shadow: 0px 3px 8px rgba(88, 98, 113, 0.15);
    overflow: hidden;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      height: 340px;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      .h3 {
        font-size: 18px;
      }
    }
  }
}
</style>
