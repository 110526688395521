<template>
  <component
    :is="isExternalResource ? 'a' : 'router-link'"
    :href="resource"
    :target="isExternalResource ? '_blank' : ''"
    :to="{ name: 'information-details', params: { id: articleId } }"
    class="info-article-list-item"
  >
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="article-date">
            {{ date | dateFormat('MMMM DD, YYYY') }}
          </p>
        </v-col>

        <template v-if="$vuetify.breakpoint.mdAndDown && thumbnail">
          <v-col cols="12">
            <v-img
              :alt="article.title"
              :aspect-ratio="1"
              width="100%"
              height="168"
              :src="thumbnail"
              class="rounded article-image"
            />
          </v-col>
        </template>
      </v-row>

      <v-row no-gutters>
        <v-col class="mr-6">
          <template v-if="article.title">
            <vue-markdown
              :source="article.title"
              :postrender="convertArticleTitle"
            />
          </template>

          <template v-if="article.description">
            <vue-markdown
              :source="article.description"
              :postrender="convertArticleDescription"
            />
          </template>

          <p class="article-tags">
            {{ tags }}
          </p>
        </v-col>

        <template v-if="!$vuetify.breakpoint.mdAndDown && thumbnail">
          <v-col cols="auto">
            <v-img
              :alt="article.title"
              :aspect-ratio="1"
              width="168"
              :src="thumbnail"
              class="rounded article-image"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </component>
</template>

<script>
import ILArticleHelperMixins from '@/modules/information/mixins/ILArticleHelperMixins';

export default {
  name: 'InfoArticlesListItem',
  mixins: [ILArticleHelperMixins],
};
</script>

<style scoped lang="scss">
a.info-article-list-item {
  text-decoration: none;
  color: #000;
}

.article {
  &-date {
    @include font-size(14, 150);
    color: var(--v-grey7-base);
    margin-bottom: 11px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-bottom: 8px;
      @include font-size(16, 171.4283);
    }
  }

  &-image {
    margin-bottom: 8px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-bottom: 0;
    }
  }

  &-tags {
    color: var(--v-grey7-base);
    margin-bottom: 0;
    @include font-size(14, 150);
  }
}

::v-deep {
  .article-title {
    color: #05090f;
    margin-bottom: 8px;
    @include font-size(18, 150, 600);

    @media (min-width: map-get($grid-breakpoints, md)) {
      @include font-size(20, 150, 600);
    }
  }

  .article-body {
    color: var(--v-grey8-base);
    margin-bottom: 24px;
    @include font-size(16, 150);

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-bottom: 16px;
    }
  }
}
</style>
