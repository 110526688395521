<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <template v-if="!$vuetify.breakpoint.mdAndUp">
          <v-divider />
        </template>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-col class="pt-6 pt-md-3 pb-0">
            <h2 class="articles-list-title pb-3">{{ sectionTitle }}</h2>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <v-divider />
            </template>
          </v-col>
        </v-row>

        <template v-if="articlesList.length > 0">
          <v-row v-for="(article, index) in articlesList" :key="index">
            <v-col cols="12" @click="pushArticleClick(article)">
              <InfoArticlesListItem :article="article" />
              <v-divider class="mt-6" />
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <InfoLandingNoArticles />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InformationMixins from '@/modules/information/mixins/InformationMixins';
import InfoArticlesListItem from '@/modules/information/features/infoLanding/InfoArticlesListItem';
import InfoLandingNoArticles from '@/modules/information/features/infoLanding/InfoLandingNoArticles';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'InfoLandingArticlesList',
  components: { InfoArticlesListItem, InfoLandingNoArticles },
  mixins: [InformationMixins, GoogleTagManagerMixins],

  computed: {
    sectionTitle() {
      let title = '';
      const { city, tags } = this.$route.query;

      if (this.hasQueryTags && this.hasQueryCity) {
        title = this.$t('information.article_section_title_location_tags', {
          city,
          tags,
        });
      } else if (this.hasQueryCity) {
        title = this.$t('information.article_section_title_location', { city });
      } else if (this.hasQueryTags) {
        title = this.$t('information.article_section_title_tags', { tags });
      } else {
        title = this.$t('information.article_section_title');
      }

      return title;
    },
  },

  hasQueryCity() {
    return this.$route.query && this.$route.query.city;
  },

  hasQueryTags() {
    return this.$route.query && this.$route.query.tags;
  },
};
</script>

<style scoped lang="scss">
.articles-list-title {
  @include font-size(24, 36, 600);
  color: var(--v-grey8-base);
}
</style>
