<template>
  <v-container fluid class="info-center">
    <v-row no-gutters>
      <v-col cols="12">
        <FlightAlert
          @searchCity="searchCity"
          ref="FlightAlert"
          :link-text="alertLink"
          isSearchWithCity
        />
      </v-col>
    </v-row>

    <v-row no-gutters justify="center">
      <v-col cols="12" sm="11">
        <template v-if="featuredArticle">
          <v-row dense>
            <v-col cols="12">
              <InfoLandingFeaturedArticle :article="featuredArticle" />
            </v-col>
          </v-row>
        </template>

        <v-row dense>
          <v-col sm="8" cols="12" order="2" order-sm="1">
            <InfoLandingArticlesList />
          </v-col>
          <v-col sm="4" cols="12" order="1" order-sm="2">
            <template v-if="relatedTopics && relatedTopics.length > 0">
              <InfoArticleRelatedTopics :related-topics-tags="relatedTopics" />
            </template>

            <InfoLandingSearch ref="SearchComponent" @showAlert="getAlert" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InfoLandingFeaturedArticle from '@/modules/information/features/infoLanding/InfoLandingFeaturedArticle';
import InfoLandingArticlesList from '@/modules/information/features/infoLanding/InfoLandingArticlesList';
import InformationMixins from '@/modules/information/mixins/InformationMixins';
import InfoLandingSearch from '@/modules/information/features/infoLanding/InfoLandingSearch';
import InfoArticleRelatedTopics from '@/modules/information/features/InfoArticleRelatedTopics';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import FlightAlert from '@/core/components/FlightAlert';

export default {
  name: 'InfoLandingContent',
  mixins: [InformationMixins, SelectedContextFlightMixins],
  components: {
    InfoArticleRelatedTopics,
    InfoLandingArticlesList,
    InfoLandingFeaturedArticle,
    InfoLandingSearch,
    FlightAlert,
  },

  methods: {
    searchCity() {
      if (this.selectedContextFlight) {
        this.$refs.SearchComponent.autoSearch();
      }
    },

    getAlert() {
      this.$refs.FlightAlert.showAlert();
    },
  },

  computed: {
    featuredArticle() {
      if (this.articlesList) {
        const allFeatured = this.articlesList.filter((article) =>
          Boolean(article.flag_promote)
        );
        if (allFeatured.length > 0) {
          return allFeatured[allFeatured.length - 1];
        }
      }
      return null;
    },

    alertLink() {
      return this.$t('product.search_in_city', {
        city: this.getCity,
      });
    },

    getCity() {
      let flightInfo = this.selectedContextFlight;
      return flightInfo ? flightInfo.destination.city : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.info-center {
  ::v-deep .info-art-related {
    margin: 0 12px;
    padding-top: 30px;
    border-top: 1px solid #cfd5e0;
    width: auto;

    @media (min-width: map-get($grid-breakpoints, md)) {
      border: none;
      padding-top: 22px;
    }

    h3 {
      @include font-size(20, 150, 700);
      color: #05090f;
    }
  }
}
</style>
